import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const language = process.env.REACT_APP_LANGUAGE || 'sv';
console.log('env: ' + process.env.REACT_APP_LANGUAGE);
console.log(language);

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: language,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            metadata: {
                title: 'ADV-X Tennis - Fitness app for tennis players',
            },
            pages: {
                home: {
                    about: {
                        title_pre: 'Your goal,',
                        title_post: 'our game',
                        text: 'Whether you\'re aiming for a stronger forehand, backhand, or serve, we have the training plan that will help you reach your goal. Our plans are based on your information and are completely tailored to your circumstances.',
                    },
                    banner: {
                        title_pre: 'ADV-X Tennis',
                        title_post: 'The fitness app that will take your tennis to the next level',
                        text: 'Is your goal a more powerful serve, a stronger forehand, or more strength in your backhand? Regardless of what it is, we create customized training plans that assist you on the way there, whether you\'re a beginner, recreational player, or competitive athlete.',
                    },
                    download_button: {
                        text: 'Download app'
                    },
                    experience: {
                        title_pre: 'Our experience,',
                        title_post: 'your advantage',
                        text: 'With over 20 years of experience, we are confident that physical fitness affects tennis performance. Benefit from our knowledge and training plans - tailored to your goals and circumstances.',
                    },
                    faq: {
                        about: {
                            title: 'About us',
                            text: 'ADV-X is a Swedish sports tech company that aims to establish a global standard for tennis fitness. Our goal is that no tennis player should have to guess how to train to perform better on the court. Therefore, we offer optimized and individually developed fitness plans and coaching that take your tennis to the next level - digitally and globally.',
                        },
                        faq_section: {
                            title: 'FAQ',
                            pricing: {
                                title: 'What does it cost?',
                                text: 'You can subscribe monthly for $14.99/month. We have no commitment period, and you can cancel the subscription at any time.',
                            },
                            for_whom: {
                                title: 'Who is the app for?',
                                text: 'ADV-X is for anyone who wants to develop their game and physical fitness. We start with your personal goals and guide you at every step with the help of individually tailored training plans.',
                            },
                            only_elite: {
                                title: 'Are you only for elite players?',
                                text: 'Our training plans are customized for each individual and are therefore equally suitable for recreational players as well as competitive athletes, regardless of age and skill level.',
                            },
                            science: {
                                title: 'How do I know if it works?',
                                text: 'We have conducted thousands of tests on tennis players and studied the correlation between physical fitness and ranking. This knowledge forms the basis of our training plans, which have been proven effective in improving physical performance over time.',
                            },
                            commitment: {
                                title: 'How often and for how long do I need to train to get better at tennis?',
                                text: 'Every player is unique, and how often and how long you need to train depends on your skill level, your goals, and how much time you can dedicate to your training. However, we have a minimum requirement of one completed session lasting 20 minutes per week, for a period of 4 weeks.',
                            },
                            training_plans: {
                                title: 'How many training plans and exercises are there?',
                                text: 'Each individual training plan has a specific goal and consists of 10-20 different exercises, allowing for the creation of countless variations of training plans. The plans are based on one of our six main tennis goals, including stronger groundstrokes and serves. In total, we offer over 300 different exercises adapted for both gym and home environments.',
                            },
                            what_if: {
                                title: 'What happens if I don\'t have time to complete my session or want to change my goal and training plan?',
                                text: 'No problem! If you miss a session, we\'ll reschedule it for the next planned opportunity. There are no limitations on how many training plans you can create - if you want to set a new goal, we\'ll create a new training plan together.',
                            },
                        },
                        contact: {
                            title: 'Contact us',
                            company_name: 'AIM-X Sports Tech AB',
                            address: 'Domherrevagen 11 B',
                            postal_code: '192 55 Sollentuna, Sweden',
                        }
                    },
                    mobile: {
                        title: 'You are ready.',
                        text: 'What are you waiting for? In the ADV-X app, you can access 20 years of accumulated knowledge entirely digitally. We are with you in every stroke along the way.',
                        cta: 'Try 7 days for free',
                    },
                    science: {
                        title: 'The science behind ADV-X',
                        text: 'For 20 years, we\'ve tested and trained thousands of tennis players at all levels – from beginners to elite athletes and global stars. That\'s why we know that 50% of your performance on the tennis court depends on technique, with the rest relying on your physical capabilities, something our tennis-specific exercises can assist you with. Our training plans have proven effectiveness, and regardless of your current level, our training plans will take you to the next.',
                        cta: 'Our history, your advantage.',
                    },
                    testimonial: {
                        1: {
                            text: '“My individual training program is a significant contributing factor to my success in reaching the top of the world"',
                            addr: 'Rebecca Peterson',
                        },
                        2: {
                            text: '“The training plans prepared me to pass the fitness tests for college in the USA”',
                            addr: 'Wilhelm Karlsson',
                        },
                        3: {
                            text: '“I feel stronger and happier with specific fitness plans that have helped me play better tennis and avoid potential injuries”',
                            addr: 'Rana Mostaghel',
                        },
                    }
                },
            }
        }
      },
      sv: {
        translation: {
            metadata: {
                title: 'ADV-X Tennis - Fitnessapp för tennisspelare',
            },
            pages: {
                home: {
                    about: {
                        title_pre: 'Ditt mål,',
                        title_post: 'vår match',
                        text: 'Oavsett om du strävar efter en starkare forehand, backhand eller serve har vi träningsplanen som hjälper dig nå ditt mål. Våra planer baseras på din information och skräddarsys helt efter dina förutsättningar.',
                    },
                    banner: {
                        title_pre: 'ADV-X Tennis',
                        title_post: 'fitnessappen som tar din tennis till nästa nivå',
                        text: 'Är ditt mål en hårdare serve, starkare forehand eller mer kraft i backhand? Oavsett vad så skapar vi skräddarsydda träningsplaner som hjälper dig på vägen dit, vare sig du är nybörjare, motionär eller tävlingsspelare.',
                    },
                    download_button: {
                        text: 'Ladda ner appen'
                    },
                    experience: {
                        title_pre: 'Vår erfarenhet,',
                        title_post: 'din fördel',
                        text: 'Med mer än 20 års erfarenhet är vi säkra på att fysiken påverkar tennisprestationen. Ta del av vår kunskap och våra träningsplaner - skräddarsydda efter dina mål och förutsättningar.',
                    },
                    faq: {
                        about: {
                            title: 'Om oss',
                            text: 'ADV-X är ett svenskt sporttech-bolag som strävar efter att skapa en global standard för fitness inom tennis. Vårt mål är att ingen tennisspelare ska behöva gissa sig till hur de ska träna för att prestera bättre på banan. Därför erbjuder vi optimerade och personligt utvecklade fitnessplaner och coaching som tar din tennis till nästa nivå - digitalt och globalt.',
                        },
                        faq_section: {
                            title: 'FAQ',
                            pricing: {
                                title: 'Vad kostar det?',
                                text: 'Vi har olika prenumerationer. Man kan prenumerera per månad för 149 kr/månad. 6 månader för 599 kr eller 12 månader för 999 kr. Vi har ingen bindningstid och du kan avsluta abonnemanget när som helst.',
                            },
                            for_whom: {
                                title: 'Vem är appen till för?',
                                text: 'ADV-X är till för alla som vill utveckla sitt spel och sin fysik. Vi utgår från dina personliga mål och guidar dig i varje steg med hjälp av personligt anpassade träningsplaner.',
                            },
                            only_elite: {
                                title: 'Är ni bara till för elitspelare?',
                                text: 'Våra träningsplaner anpassas efter varje person och lämpar sig därför lika bra för motionärer som för tävlingsspelare, oavsett ålder och spelnivå.',
                            },
                            science: {
                                title: 'Hur vet jag att det funkar?',
                                text: 'Vi har genomfört tusentals tester på tennisspelare och studerat sambandet mellan fysik och ranking. Denna kunskap ligger till grund för våra träningsplaner som är bevisat effektiva när det gäller förbättring av fysisk prestation över tid.',
                            },
                            commitment: {
                                title: 'Hur ofta och hur länge behöver jag träna för att bli bättre på tennis?',
                                text: 'Varje spelare är unik och hur ofta och länge du behöver träna beror på din spelnivå, vilka mål du har och hur mycket tid du kan spendera på din träning. Vi har däremot ett minimikrav på ett genomfört pass på minst 20 minuter i veckan, under 4 veckors tid.',
                            },
                            training_plans: {
                                title: 'Hur många träningsplaner och övningar finns det?',
                                text: 'Varje enskild träningsplan har ett specifikt mål och består av 10-20 olika övningar, vilket gör det möjligt att skapa oändligt många varianter av träningsplaner. Planerna baseras på ett av våra sex huvudsakliga tennismål, däribland hårdare grundslag och serve. Sammantaget erbjuder vi över 300 olika övningar som är anpassade både för gym- och hemmamiljö.',
                            },
                            what_if: {
                                title: 'Vad händer om jag inte hinner genomföra mitt pass eller vill byta mål och träningsplan?',
                                text: 'Inga problem! Missar du ett pass så skjuter vi på det till nästa planerade tillfälle. Det finns inga begränsningar i hur många träningsplaner du kan skapa - vill du sätta upp ett nytt mål så skapar vi en ny träningsplan tillsammans.',
                            },
                        },
                        contact: {
                            title: 'Kontakta oss',
                            company_name: 'AIM-X Sports Tech AB',
                            address: 'Domherrevägen 11 B',
                            postal_code: '192 55 Sollentuna',
                        }
                    },
                    mobile: {
                        title: 'Du är redo.',
                        text: 'Vad väntar du på? I ADV-X-appen kan du ta del av 20 år av samlad kunskap helt digitalt. Vi är med dig i varje slag på vägen.',
                        cta: 'Testa 7-dagar gratis',
                    },
                    science: {
                        title: 'Vetenskapen bakom ADV-X',
                        text: 'Vi har under 20 år både testat och tränat tusentals tennisspelare på alla nivåer – från motionärer till elitspelare och världsstjärnor. Därför vet vi att 50% av din prestation på tennisbanan beror på teknik och resten på dina fysiska förutsättningar, något som våra tennisspecifika övningar kan hjälpa dig med. Våra träningsplaner har bevisad effekt och oavsett vilken nivå du befinner dig på i dagsläget, kommer våra träningsplaner att ta dig till nästa.',
                        cta: 'Vår historia är din fördel.',
                    },
                    testimonial: {
                        1: {
                            text: '“Mitt individuella träningsprogram är en stark bidragande orsak till att jag lyckats nå världstoppen"',
                            addr: 'Rebecca Peterson',
                        },
                        2: {
                            text: '“Träningsplanerna gjorde mig redo att klara fystesterna till college i USA”',
                            addr: 'Wilhelm Karlsson',
                        },
                        3: {
                            text: '“Jag känner mig starkare och gladare med specifika fysplaner som hjälpt mig att spela bättre tennis och undvika eventuell skador”',
                            addr: 'Rana Mostaghel',
                        },
                    }
                },
            }
        }
      }
    }
  });

export default i18n;